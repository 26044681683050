import "core-js/stable";
import "regenerator-runtime/runtime";
import ResponsiveAutoHeight from "responsive-auto-height";
import { initScrollReveal } from "./scroll-reveal";

/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

/*==========================================
 Click Events Listeners
===========================================*/

bodyElement.addEventListener("click", evt => {});

/*==========================================
 Init plugins
===========================================*/

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();

        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Equal Height
===========================================*/

const runMatchHeight = () => {
    if (document.querySelector(".match-height")) {
        new ResponsiveAutoHeight(".match-height");
    }
    if (document.querySelector(".match-height2")) {
        new ResponsiveAutoHeight(".match-height2");
    }
};

runMatchHeight();


/*==========================================
Accordion
===========================================*/

var accordions = document.getElementsByClassName("accordion-title");

for (var i = 0; i < accordions.length; i++){
    accordions[i].addEventListener("click", function(){
        this.classList.toggle('active');
        var accordionContent = this.nextElementSibling;
        if(accordionContent.style.maxHeight){
            accordionContent.style.maxHeight = null;
        } else {
            accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
        }
    })
}

/*==========================================
Video modal
===========================================*/

const vidButtons = document.querySelectorAll(".js-video-btn");

if (vidButtons && vidButtons.length){
    const vidModal = document.querySelector("#js-video-modal");
    const vidPlayer = document.querySelector("#vidPlayer");
    
    vidButtons.forEach(item => {
        item.addEventListener('click', e => {
            let vidSrc = `https://player.vimeo.com/video/${item.dataset.video}?autoplay=1&title=0&byline=0&portrait=0`;
            vidPlayer.setAttribute('src', vidSrc);
            vidModal.classList.add('active');
        });
    });

    vidModal.addEventListener('click', e => {
        vidPlayer.setAttribute('src', '');
        vidModal.classList.remove('active');
    })
}

/*==========================================
Mobile menu
===========================================*/

const mobileBtn = document.querySelector(".js-mobile-btn");

mobileBtn.addEventListener("click", e => {
    document.querySelector(".o-header").classList.toggle("active");
});

/*==========================================
Account for browser bar on mobile
===========================================*/

let vh = window.innerHeight * 0.01; 
document.documentElement.style.setProperty('--vh', `${vh}px`); 

window.addEventListener('resize', () => { 
    let vh = window.innerHeight * 0.01; 
    document.documentElement.style.setProperty('--vh', `${vh}px`); 
});

/*==========================================
Sub menu links
===========================================*/

const parentItems = document.querySelectorAll('.v-parent');
const menuOverlay = document.getElementById('js-menu-overlay');

parentItems.forEach(item => {
    item.addEventListener('mouseenter', e => {
        item.getElementsByClassName('v-children')[0].classList.add('active');
        menuOverlay.classList.add('v-open');
    })
    item.addEventListener('mouseleave', e => {
        item.getElementsByClassName('v-children')[0].classList.remove('active');
        menuOverlay.classList.remove('v-open');
    })
})

