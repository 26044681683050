import ScrollReveal from "scrollreveal";

/*==========================================
  Scroll Reveal 
===========================================*/

const scrollRevealAfterCallback = el => {
    el.classList.add("v-slide-in");
};

const scrollRevealBeforeCallback = el => {
    el.classList.add("v-sr-before-visible");
};

export const srOptions = {
    distance: "500px",
    viewOffset: {
        top: 240
    },
    duration: 1500,
    delay: 1000,
    duration: 0,
    opacity: 1,
    distance: "0px",
    cleanup: true,
    afterReveal: scrollRevealAfterCallback
};

export const initScrollReveal = () => {
    const scrollRevealSelectors = "";

    ScrollReveal().reveal(scrollRevealSelectors, srOptions);

    if (document.querySelector(".v-scroll-reveal")) {
        ScrollReveal().reveal(".v-scroll-reveal", srOptions);
    }
};
